<!-- 维修任务 -->

<template>
  <div id="test-task">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>维修任务</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <!-- <el-form-item label="变配电站">
              <el-select :popper-append-to-body="false" v-model="requestParam.stationId" placeholder="请选择" clearable>
                <el-option v-for="item in stationData" :key="item.id" :label="item.stationName"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="变配电站">
              
              <el-input v-model.trim="requestParam.stationName" placeholder="请输入场站名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="任务标题">
              <el-input v-model.trim="requestParam.taskName" placeholder="请输入任务编号" clearable></el-input>
            </el-form-item>
            <el-form-item label="任务编号">
              <el-input v-model.trim="requestParam.taskNum" placeholder="请输入任务编号" clearable></el-input>
            </el-form-item>
            <el-form-item label="任务状态">
              <el-select :popper-append-to-body="false" v-model="requestParam.state" placeholder="请选择" clearable>
                <el-option label="保存" value="1"></el-option>
                <el-option label="待执行" value="2"></el-option>
                <el-option label="执行中" value="3"></el-option>
                <el-option label="完成待审批" value="4"></el-option>
                <el-option label="终止" value="5"></el-option>
                <el-option label="审批通过" value="6"></el-option>
                <el-option label="审批驳回" value="7"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="运维审批人">
              <el-input v-model.trim="requestParam.approverName" placeholder="请输入运维审批人名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="运维执行人">
              <el-input v-model.trim="requestParam.operationUserName" placeholder="请输入运维执行人名称" clearable></el-input>
            </el-form-item>
            <el-form-item class="query-date" label="计划开始时间">
              <el-date-picker v-model="requestParam.planStartAtStart" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择计划开始时间"></el-date-picker>
              <!-- <span> ~ </span>
              <el-date-picker v-model="requestParam.planStartAtEnd" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择" :picker-options="pickerOptions"></el-date-picker> -->
            </el-form-item>
            <el-form-item class="query-date" label="计划结束时间">
              <el-date-picker v-model="requestParam.planStartAtEnd" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择计划结束时间" :picker-options="pickerOptions"></el-date-picker>
            </el-form-item>
            <el-form-item class="query-date" label="实际开始时间">
              <el-date-picker v-model="requestParam.startAtStart" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择实际开始时间"></el-date-picker>
              <!-- <span> ~ </span>
              <el-date-picker v-model="requestParam.startAtEnd" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择实际结束时间" :picker-options="pickerOptions"></el-date-picker> -->
            </el-form-item>
            <el-form-item class="query-date" label="实际结束时间">
              <el-date-picker v-model="requestParam.startAtEnd" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择实际结束时间" :picker-options="pickerOptions"></el-date-picker>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button @click="formQuery" ref="searchButton" type="primary" icon="el-icon-search">查询</el-button>
            <el-button :class="isStringInArray('btnTaskReleased') ? '' : 'btnShowAuthority'" type="primary"
              icon="el-icon-s-order" @click="createDialog">任务发布</el-button>
          </div>
        </div>

        <el-table class="table" :data="tableData" v-loading="loading" element-loading-text="加载中"
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.3)">
          <div slot="empty">
            <div v-if="tableData.length == 0">
              <div class="empty-data-view">
                <div class="table-text">
                  {{ loading ? "" : "暂无数据" }}
                </div>
              </div>
            </div>
          </div>
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
            (requestParam.pageNum - 1) * requestParam.pageSize +
            scope.$index +
            1
          }}</span>
            </template>
          </el-table-column>

          <el-table-column align="center" prop="stationName" label="变配电站" width="150">
          </el-table-column>
          <el-table-column align="center" prop="stationNumber" label="场站编码" width="150">
          </el-table-column>
          <el-table-column align="center" prop="companyName" label="运维单位" width="150">
          </el-table-column>
          <el-table-column align="center" prop="taskName" label="任务标题" width="150">
          </el-table-column>
          <el-table-column align="center" prop="taskNum" label="维修任务编号" width="150">
          </el-table-column>
          <el-table-column align="center" prop="stateDict" label="任务状态" width="150">

            <template slot-scope="scope">
              <div v-show="scope.row.stateDict === '保存'" style="color: #aed6ff">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '待执行'" style="color: #66b1ff">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '执行中'" style="color: yellow">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '完成待审批'" style="color: #67c23a">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '待审批'" style="color: #67c23a">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '终止'" style="color: red">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '通过'" style="color: #0DBC79">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '驳回'" style="color: #d12018">
                {{ scope.row.stateDict }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="approverName" label="运维审批人" width="100">
          </el-table-column>
          <el-table-column align="center" prop="operationUserName" label="运维执行人" width="100">
          </el-table-column>
          <el-table-column align="center" prop="createName" label="创建人" width="100">
          </el-table-column>
          <el-table-column align="center" prop="planStartTime" label="计划开始时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="planEndTime" label="计划结束时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间" width="175">
          </el-table-column>
          <el-table-column align="center" prop="startTime" label="实际开始时间" width="175">
          </el-table-column>
          <el-table-column align="center" prop="endTime" label="实际结束时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="submitTime" label="提交时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间" width="175">
          </el-table-column>

          <el-table-column align="center" prop="remark" label="备注" width="200">
          </el-table-column>
          <!-- <el-table-column align="center" prop="resultDesc" label="执行结果" width="150">
          </el-table-column> -->

          <!-- <el-table-column align="center" prop="count" label="巡检项数">
          </el-table-column> -->

          <!-- <el-table-column align="center" prop="email5" label="缺陷未处理数" width="175">
          </el-table-column> -->
          <el-table-column align="center" label="操作" prop="operate" width="250" fixed="right">

            <template slot-scope="scope">
              <div class="operate">
                <div v-if="scope.row.state === 1 || scope.row.state === 7">
                  <el-button type="text" :class="isStringInArray('btnSubmitd') ? '' : 'btnShowAuthority'"
                    @click="submitDialog(scope.row)">提交</el-button>
                  <span :class="isStringInArray('btnSubmitd') ? '' : 'btnShowAuthority'">|</span>
                  <el-button type="text" :class="isStringInArray('btnEditd') ? '' : 'btnShowAuthority'"
                    @click="updateDialog(scope.row)">编辑</el-button>
                  <span :class="isStringInArray('btnEditd') ? '' : 'btnShowAuthority'">|</span>
                </div>
                <div v-if="scope.row.state === 2" :class="isStringInArray('btnImplementd') ? '' : 'btnShowAuthority'">
                  <el-button type="text" @click="statusDialog(scope.row, 3)">执行</el-button>
                  <span>|</span>
                </div>
                <div v-if="scope.row.state === 4 && scope.row.approverId==userId" :class="isStringInArray('btnApprovald') ? '' : 'btnShowAuthority'">
                  <el-button type="text" @click="approveDialog(scope.row)">审批</el-button>
                  <span>|</span>
                </div>
                <div v-if="scope.row.state === 3" :class="isStringInArray('btnCompleted') ? '' : 'btnShowAuthority'">
                  <el-button type="text" @click="executeDialog(scope.row, 4)">完成</el-button>
                  <span>|</span>

                </div>
                <div v-if="scope.row.state === 2 || scope.row.state === 3|| scope.row.state === 4"
                  :class="isStringInArray('btnStopd') ? '' : 'btnShowAuthority'">
                  <el-button type="text" @click="statusDialog(scope.row, 5)">终止</el-button>
                  <span>|</span>
                </div>

                <el-button type="text" @click="viewDialog(scope.row)">详情</el-button>
                <!-- <span v-if="scope.row.state === 7">|</span>
                <el-button v-if="scope.row.state === 7" type="text" @click="approveRejectDialog(scope.row)">审核驳回</el-button> -->
                <span :class="isStringInArray('btnDeleted') ? '' : 'btnShowAuthority'">|</span>
                <el-button :class="isStringInArray('btnDeleted') ? '' : 'btnShowAuthority'" type="text"
                  @click="deleteDialog(scope.row)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model.number="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="查看详情" :visible.sync="viewVisible" width="800px" @close="dialogClose('taskForm')">
      <div class="dialog-content">
        <div class="form-title">基本信息</div>
        <el-form class="dialog-form" ref="taskForm" :inline="true" :model="detailsData">
          <el-form-item label="场站名称">
            <el-input v-model="detailsData.stationName" :readonly="true"></el-input>
          </el-form-item>
          <div class=""></div>
          <el-form-item label="任务名称">
            <el-input v-model="detailsData.taskName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="任务编号">
            <el-input v-model="detailsData.taskNum" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维单位">
            <el-input v-model="detailsData.companyName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="任务状态">
            <el-input v-model="detailsData.stateDict" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建人">
            <el-input v-model="detailsData.createName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="单位负责人">
            <el-input v-model="detailsData.companyPrincipalName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维执行人">
            <el-input v-model="detailsData.operationUserName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维审核人">
            <el-input v-model="detailsData.approverName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item>

          <!-- <el-form-item label="负责人">
            <el-input v-model="detailsData.principalName" :readonly="true"></el-input>
          </el-form-item> -->
         
          <el-form-item label="总金额(元)">
            <el-input v-model="detailsData.sumMoney" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="提交时间">
            <el-input v-model="detailsData.submitTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划开始时间" class="special">
            <el-input v-model="detailsData.planStartTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划结束时间">
            <el-input v-model="detailsData.planEndTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际开始时间" class="special">
            <el-input v-model="detailsData.startTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际结束时间">
            <el-input v-model="detailsData.endTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="审批时间" class="">
            <el-input v-model="detailsData.unknownTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="终止时间">
            <el-input v-model="detailsData.stopTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input class="" type="textarea" v-model="detailsData.remark" :rows="3" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="图片" v-if="detailsData.url">
            <div class="" style="width: 620px;">
              <el-image style="width: 200px; height: 98px" :src="detailsData.url" fit="cover"
                :preview-src-list="detailsData.fileList"></el-image>
            </div>
          </el-form-item>

          <div class="interval"></div>
          <div class="form-title">维修项内容：</div>

          <el-form-item>
            <div class="selectLook " v-for="(item, index) in fileListBtnLook" :key="index">
              <el-form class="dialog-form myDialogForm">
                <el-form-item label="维修内容">
                  <el-input type="textarea" :rows="3" :readonly="true" v-model="item.maintainTaskExplain"
                    placeholder="请输入维修项内容"></el-input>
                </el-form-item>
              
                <el-form-item label="维修图片" >
                  <div class="fileList">
                    <div class="" v-if="item.sysFile.length > 0">
                      <div class="fileListItem" v-for=" (children, index) in item.sysFile" :key="index">
                        <el-image style="width: 100px; height: 100px" :src="children.url"
                          :preview-src-list="item.fileList">
                        </el-image>
                      </div>
                    </div>
                    <div class="noImage" v-else> 暂无</div>
                  </div>
                </el-form-item>
              </el-form>
              <el-form>

                <el-row v-if="detailsData.state > 2">
                  <el-col :span="24">
                    <el-form-item label="维修状态">
                      <el-select :disabled="true" class="resultStatus" :popper-append-to-body="false"
                        v-model="item.resultStatus" placeholder="请选择" clearable>
                        <el-option label="完成" :value="1"></el-option>
                        <el-option label="维修中" :value="2"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="维修金额(元)" v-if="detailsData.state > 2">
            <el-input v-model=" item.result.maintainMoney" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="维修结果" v-if="detailsData.state >2">
                  <el-input type="textarea" :rows="3" :readonly="true" v-model="item.result.maintainTaskExplain"
                    placeholder="请输入维修说明"></el-input>
                </el-form-item>
                <el-form-item label="维修结果图片" style="width: 700px;" v-if="detailsData.state > 2">
                  <div class="" style=" display: flex;">
                  <div class="fileListItem" v-for=" (children, index) in item.result.sysFile" :key="index">
                    <el-image style="width: 100px; height: 100px" :src="children.url"
                      :preview-src-list="item.result.fileList">
                    </el-image>
                  </div>
                </div>
          </el-form-item>
                <!-- <div class="resultText">
                  <span>维修结果:{{ item.result.maintainTaskExplain }}</span>

                </div> -->
                <!-- <div class="resultText">
                  <span>维修金额:{{ item.result.maintainMoney }}元</span>

                </div> -->
                <!-- <span>维修金额:{{ item.result }}元</span> -->
                <!-- <div class="">
                  <div class="fileListItem" v-for=" (children, index) in item.result.sysFile" :key="index">
                    <el-image style="width: 100px; height: 100px" :src="children.url"
                      :preview-src-list="item.result.fileList">
                    </el-image>
                  </div>
                </div> -->
              </el-form>
            </div>
            <div class="query-button">
              <!-- <el-button size="small" @click="addFile">添加</el-button> -->
              <!-- <el-button size="small" @click="addFile">保存</el-button> -->
            </div>
          </el-form-item>
          <div class="" v-if="detailsData.state==6||detailsData.state==7">
            <div  class="form-title">审批结果：</div>
  
            <el-form-item label="审批意见" >
              <el-input type="textarea" v-model="detailsData.approverOpinion" :rows="3" :readonly="true"></el-input>
            </el-form-item>
  
            <el-form-item label="评分"  >
              <el-rate v-if="detailsData.userScore" class="myRate" v-model="detailsData.userScore.score" disabled show-text allow-half
                text-color="#aed6ff"> </el-rate>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="viewVisible = false">取 消</el-button>
        <el-button type="primary" @click="viewVisible = false">确 定</el-button> -->
      </div>
    </el-dialog>
    <el-dialog :title="requestType === 0 ? '新建维修任务' : '修改维修任务'" :visible.sync="dialogVisible" width="840px"
      @close="dialogClose('taskForm')">
      <div class="dialog-content">
        <div class="form-title">基本信息</div>
        <el-form class="dialog-form" ref="taskForm" :inline="true" :model="taskForm" :rules="rules">
          <el-form-item label="变配电站" prop="stationId">
            <el-input style="width: 330px"  placeholder="请选择变配电站" v-model="stationName" :readonly="true">

              <template slot="append">
                <span @click="selectStation">选择配电站</span>
              </template>
            </el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="任务标题" prop="taskName">
                <el-input class="" placeholder="请输入任务标题" clearable v-model.trim="taskForm.taskName">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="运维单位" prop="companyId">
                <!-- <el-input class="block" placeholder="请输入任务标题" clearable v-model.trim="taskForm.taskName">
                </el-input> -->
                <el-select :popper-append-to-body="false" clearable v-model="taskForm.companyId"
                  placeholder="请选择运维单位" @change="changeObtainUser">
                  <el-option v-for="item in ComList" :key="item.companyId" :label="item.companyName"
                    :value="item.companyId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="单位负责人" >
                <el-input   placeholder="请输入单位负责人" :readonly="true" clearable v-model.trim="approverName">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="运维审批人" prop="approverId">
                <!-- <el-input   placeholder="请输入审批人" :readonly="true" clearable v-model.trim="approverName">
                </el-input> -->
                  <el-select :popper-append-to-body="false" v-model="taskForm.approverId" placeholder="请选择运维审批人">
              <el-option v-for="item in operationUserList" :key="item.userId" :label="item.userName"
                :value="item.userId"></el-option>
            </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="运维执行人" prop="operationUserId">
                <el-select :popper-append-to-body="false" clearable v-model="taskForm.operationUserId"
                  placeholder="请选择执行人">
                  <el-option v-for="item in userData" :key="item.id" :label="item.userName"
                    :value="item.userId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="计划开始时间" prop="planStartTime">
                <el-date-picker v-model="taskForm.planStartTime" type="datetime" placeholder="请选择计划开始时间"
                  :picker-options="pickerOptionsStart" value-format="yyyy-MM-dd HH:mm:ss" :append-to-body="false"
                  @change="checkDateTime"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="计划结束时间" prop="planEndTime">
                <el-date-picker v-model="taskForm.planEndTime" type="datetime" placeholder="请选择计划完成时间"
                  value-format="yyyy-MM-dd HH:mm:ss" :append-to-body="false" @change="checkDateTime"
                  :picker-options="pickerOptionsEnd"></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
       
          <el-form-item label="备注">
            <el-input class="basisRemark" placeholder="请输入备注信息" type="textarea" :rows="3" v-model.trim="taskForm.remark"></el-input>
          </el-form-item>
          <!-- <el-form-item label="图片">
            <el-upload action="" :auto-upload="false" :limit="1" accept="image/*" :file-list="fileList"
              :on-change="pictureChange" list-type="picture" :on-remove="handleRemove">
              <el-button size="small" type="primary">点击上传</el-button>
              <span slot="tip" class="el-upload-tip">
                只能上传jpg/png文件,且不超过5M,建议分辨率900*400
              </span>
            </el-upload>
          </el-form-item> -->
          <div class="interval"></div>
          <div class="query-button btnRight">
              <el-button size="small" @click="addFile">添加</el-button>
              <!-- <el-button size="small" @click="addFile">保存</el-button> -->
            </div>
          <!-- <div class="form-title">维修项内容：</div> -->
          <el-form-item label="维修项内容" class="">
            <div class="select" v-for="(item, index) in fileListBtn" :key="index">
              <el-form>
                <el-form-item label="维修项内容">
                  <el-input class="fileListBtnCls" type="textarea" :rows="3" v-model.trim="item.maintainTaskExplain"
                    placeholder="请输入维修项内容"></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="imgFile">
                    <div class="imgFileText">
                      图片 :
                    </div>
                    <el-upload action="" :auto-upload="false" :limit="10" accept="image/*" :file-list="item.sysFile"
                      :on-change="(file, fileList) => pictureChangeService(file, fileList, index, 2, item)"
                      list-type="picture"
                      :on-remove="(file, fileList) => handleRemoveTaskItem(file, fileList, index, 2, item)">
                      <el-button size="small" type="primary">点击上传</el-button>
                      <span slot="tip" class="el-upload-tip">
                        只能上传jpg/png文件,且不超过5M,建议分辨率900*400
                      </span>
                      <!-- <span slot="tip" class="el-upload-tip ">
                        <el-button v-if="fileListBtn.length != 1" size="small" type="primary"
                          @click="deleteTerm(index, item)">删除此维修项</el-button>
                      </span> -->
                    </el-upload>
                    
                  </div>
                </el-form-item>
              </el-form>
              <div class="deleteItem" v-if="fileListBtn.length != 1" @click="deleteTerm(index, item)">
                <i class="el-icon-circle-close"></i>
              </div>
            </div>
            
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeStatus(1, 'taskForm')">保存</el-button>
        <el-button v-if="requestType === 0" type="primary" @click="changeStatus(2, 'taskForm')">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">确定要删除该数据吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteTestTask">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提交" :visible.sync="submitVisible" width="436px">
      <div class="dialog-text">任务一旦提交将无法修改,请确认</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="submitVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="statusType === 3 ? '执行任务' : '终止任务'" :visible.sync="statusVisible" width="431px">
      <div class="dialog-text">
        {{ statusType === 3 ? "确定执行该任务。" : "确定将状态改成终止。" }}
      </div>
    
      <div slot="footer" class="dialog-footer">
        <el-button @click="statusVisible = false">取 消</el-button>
        <el-button type="primary" @click="statusConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="执行结果" :visible.sync="executeVisible" width="800px" @close="dialogClose('taskForm')">
      <div class="dialog-content">
        <el-form class="dialog-form " ref="taskForm" :inline="true" :model="executeResult" :rules="rules">
          <el-form-item label="场站名称">
            <el-input v-model="detailsData.stationName" :readonly="true"></el-input>
          </el-form-item>
          <div class=""></div>
          <el-form-item label="任务名称">
            <el-input v-model="detailsData.taskName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="任务编号">
            <el-input v-model="detailsData.taskNum" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维单位">
            <el-input v-model="detailsData.companyName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="任务状态">
            <el-input v-model="detailsData.stateDict" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建人">
            <el-input v-model="detailsData.createName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="单位负责人">
            <el-input v-model="detailsData.companyPrincipalName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维执行人">
            <el-input v-model="detailsData.operationUserName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维审核人">
            <el-input v-model="detailsData.approverName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="总金额(元)">
            <el-input v-model="detailsData.sumMoney" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="提交时间">
            <el-input v-model="detailsData.submitTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划开始时间" class="special">
            <el-input v-model="detailsData.planStartTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划结束时间">
            <el-input v-model="detailsData.planEndTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际开始时间" class="special">
            <el-input v-model="detailsData.startTime" :readonly="true"></el-input>
          </el-form-item>

          <el-form-item label="备注">
            <el-input class="" type="textarea" v-model="detailsData.remark" :rows="3" :readonly="true"></el-input>
          </el-form-item>

          <el-form-item label="图片" v-if="detailsData.url">
            <div class="" style="width: 620px;">
              <el-image style="width: 200px; height: 98px" :src="detailsData.url" fit="cover"
                :preview-src-list="detailsData.fileList"></el-image>
            </div>
          </el-form-item>
          <div class="form-title">维修项内容：</div>
          <el-form-item>
            <div class=" dialogBodyItem  selectLook "
              v-for="(item, index) in completeDetails.operationMaintainTaskItemList" :key="item.id">
              <div class="itemTitle">{{ index + 1 }}：{{ item.maintainTaskExplain }} 
                <span v-if="item.operationMaintainTaskItemDetailsList.length > 0 "> <i class="el-icon-circle-check"></i> </span></div>
              <!-- <div class="fileList" >
                <span> 维修项状态：</span>
                <div v-if="formDataExecute[index].btn" class="fileListItem" style="color:yellow">
                      维修中
                </div>
                <div v-else  class="fileListItem" style="color:#10D269">
                      已完成
                </div>
              </div> -->
              <div class="fileList" >
                <span> 维修项图片：</span>
                <div v-if="item.sysFile.length > 0" class="fileListItem" v-for="itemChildren in item.sysFile" :key="itemChildren.id">
                  <el-image fit="scale-down" :preview-src-list="[itemChildren.url]" :src="itemChildren.url"
                    style="width:150px;height: 100px;"></el-image>
                </div>
                <div v-else class="noMessage">暂无</div>
              </div>
              <el-form class="dialog-form myDialogForm" label-position="right" :inline="true" :model="formDataExecute[index]" label-width="100px">
                <el-form-item label="维修项状态" :rules="{ required: true, message: '维修金额不能为空', trigger: 'blur' }">
                  <!-- <el-input class="executeItem" :readonly="!formDataExecute[index].btn" placeholder="请输入维修金额" :readonly="!formDataExecute[index].btn"
                    v-model.trim="formDataExecute[index].resultStatus"></el-input> -->
                  <el-select  class="resultStatus" :popper-append-to-body="false"
                    v-model="formDataExecute[index].resultStatus" placeholder="请选择" clearable>
                    <el-option label="完成" :value="1"></el-option>
                    <el-option label="维修中" :value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="维修金额(元)" :rules="{ required: true, message: '维修金额不能为空', trigger: 'blur' }">
                  <el-input class="executeItem" placeholder="请输入维修金额" 
                    v-model.trim="formDataExecute[index].maintainMoney"></el-input>
                </el-form-item>
                <el-form-item label="维修结果" :rules="{ required: true, message: '维修结果不能为空', trigger: 'blur' }">
                  <el-input class="executeItem" placeholder="请输入维修结果" type="textarea" :rows="3"
                    
                    v-model.trim="formDataExecute[index].maintainTaskExplain"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-form-item label="维修结果图片" :rules="{ required: true, message: '图片不能为空', trigger: 'blur' }">
                    <el-upload action="" :auto-upload="false" :limit="10"
                      accept="image/*" :file-list="formDataExecute[index].file"
                      :on-change="(file, fileList) => pictureChangeService(file, fileList, index, 3, item)"
                      list-type="picture"
                      :on-remove="(file, fileList) => handleRemoveTaskItem(file, fileList, index, 3, item)">
                      <el-button size="small" type="primary" >点击上传</el-button>
                      <span slot="tip" class="el-upload-tip">
                        只能上传jpg/png文件,且不超过5M,建议分辨率900*400
                      </span>
                    </el-upload>
                    <!-- <div class="">
                      <div class="" v-for="(item, index1) in formDataExecute[index].file" :key="index1">
                        <el-image fit="scale-down" :preview-src-list="[formDataExecute[index].file[index1].url]"
                          :src="item.url" style="width:150px;height: 100%;"></el-image>
                      </div>
                    </div> -->
                  </el-form-item>
                </el-form-item>
              </el-form>
              <!--  v-if="formDataExecute[index].btn" -->
              <div class="executePreserveBtn">
                <el-button  size="small" type="primary"
                  @click="executePreserve(item, index)">保存</el-button>
              </div>
            </div>

          </el-form-item>

        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="executeVisible = false">取 消</el-button>
        <!-- <el-button @click="executeConfirm('taskForm')">保 存</el-button> -->
        <el-button type="primary" @click="executeConfirm('taskForm')">完 成</el-button>
      </div>
    </el-dialog>
    <el-dialog title="审批" :visible.sync="approveVisible" width="850px" @close="dialogClose('statusForm')">
      <div class="dialog-content">
        <div class="form-title">基本信息</div>
        <el-form class="dialog-form" ref="taskForm" :inline="true" :model="detailsData">
          <el-form-item label="场站名称">
            <el-input v-model="detailsData.stationName" :readonly="true"></el-input>
          </el-form-item>
          <div class=""></div>
          <el-form-item label="任务名称">
            <el-input v-model="detailsData.taskName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="任务编号">
            <el-input v-model="detailsData.taskNum" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维单位">
            <el-input v-model="detailsData.companyName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="任务状态">
            <el-input v-model="detailsData.stateDict" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建人">
            <el-input v-model="detailsData.createName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="单位负责人">
            <el-input v-model="detailsData.companyPrincipalName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维执行人">
            <el-input v-model="detailsData.operationUserName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维审核人">
            <el-input v-model="detailsData.approverName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item>

          <!-- <el-form-item label="负责人">
            <el-input v-model="detailsData.principalName" :readonly="true"></el-input>
          </el-form-item> -->
         
          <el-form-item label="总金额(元)">
            <el-input v-model="detailsData.sumMoney" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="提交时间">
            <el-input v-model="detailsData.submitTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划开始时间" class="special">
            <el-input v-model="detailsData.planStartTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划结束时间">
            <el-input v-model="detailsData.planEndTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际开始时间" class="special">
            <el-input v-model="detailsData.startTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际结束时间">
            <el-input v-model="detailsData.endTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input class="" type="textarea" v-model="detailsData.remark" :rows="3" :readonly="true"></el-input>
          </el-form-item>

          <el-form-item label="图片" v-if="detailsData.url">
            <div class="" style="width: 620px;">
              <el-image style="width: 200px; height: 98px" :src="detailsData.url" fit="cover"
                :preview-src-list="detailsData.fileList"></el-image>
            </div>
          </el-form-item>

          <div class="interval"></div>
          <div class="form-title">维修项内容：</div>

          <el-form-item>
            <div class="selectLook " v-for="(item, index) in fileListBtnLook" :key="index">
              <el-form class="dialog-form myDialogForm">
                <el-form-item label="维修内容">
                  <el-input type="textarea" :rows="3" :readonly="true" v-model="item.maintainTaskExplain"
                    placeholder="请输入维修项内容"></el-input>
                </el-form-item>
                
                <el-form-item label="维修图片" v-if="item.sysFile.length > 0">
                  <div class="fileList">
                    <div class="fileListItem" v-for=" (children, index) in item.sysFile" :key="index">
                      <el-image style="width: 100px; height: 100px" :src="children.url"
                        :preview-src-list="item.fileList">
                      </el-image>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
              <el-form>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="维修状态">
                      <el-select :disabled="true" class="resultStatus" :popper-append-to-body="false"
                        v-model="item.resultStatus" placeholder="请选择" clearable>
                        <el-option label="完成" :value="1"></el-option>
                        <el-option label="维修中" :value="2"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item label="维修金额(元)">
            <el-input v-model=" item.result.maintainMoney" :readonly="true"></el-input>
          </el-form-item>
                <el-form-item label="维修结果" v-if="detailsData.state >2">
                  <el-input type="textarea" :rows="3" :readonly="true" v-model="item.result.maintainTaskExplain"
                    placeholder="请输入维修项内容"></el-input>
                </el-form-item>
                <el-form-item label="维修结果图片" style="width: 700px;">
                  <div class="" style=" display: flex;">
                  <div class="fileListItem" v-for=" (children, index) in item.result.sysFile" :key="index">
                    <el-image style="width: 100px; height: 100px" :src="children.url"
                      :preview-src-list="item.result.fileList">
                    </el-image>
                  </div>
                </div>
          </el-form-item>
                <!-- <div class="resultText">
                  <span>维修结果:{{ item.result.maintainTaskExplain }}</span>

                </div> -->
                <!-- <div class="resultText">
                  <span>维修金额:{{ item.result.maintainMoney }}元</span>

                </div> -->
                <!-- <span>维修金额:{{ item.result }}元</span> -->
                <!-- <div class="">
                  <div class="fileListItem" v-for=" (children, index) in item.result.sysFile" :key="index">
                    <el-image style="width: 100px; height: 100px" :src="children.url"
                      :preview-src-list="item.result.fileList">
                    </el-image>
                  </div>
                </div> -->
              </el-form>
            </div>
            <div class="query-button">
              <!-- <el-button size="small" @click="addFile">添加</el-button> -->
              <!-- <el-button size="small" @click="addFile">保存</el-button> -->
            </div>
          </el-form-item>
         
        </el-form>
        <div class="approveResultForm">
          <el-form class="status-form" ref="statusForm" :model="approveResult" :rules="formRules" :inline="true">
            <el-form-item label="审批结果" prop="state">
              <el-radio-group v-model="approveResult.state">
                <el-radio :label="6">同意</el-radio>
                <el-radio :label="7">驳回</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="审批意见" prop="approverOpinion">
              <el-input placeholder="请输入审批意见" type="textarea" :rows="3"
                v-model.trim="approveResult.approverOpinion"></el-input>
            </el-form-item>
            <el-form-item label="评分" >
              <el-rate v-model="approveResult.score" show-text allow-half text-color="#aed6ff"> </el-rate>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="approveVisible = false">取 消</el-button>
        <el-button type="primary" @click="approveConfirm('statusForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 变配电站选择 -->
    <stationDialog :status="dialogStatus" :stationId="stationId" @selectedStation="getStationName"
      @statusChange="changeDialog"></stationDialog>
  </div>
</template>

<script>
import {getStaComList, testTaskApi, taskListSearch, taskListAdd, taskListAddItem, taskUpdateType, taskQueryId, taskListUpdate, userScore, taskListUpdateItem, taskListState, taskListItemUpdate,taskListItemAddUpdate, deleteTAskList, deleteSysFile, uploadSysFile, taskItemDelete } from "@/api/testTask.js";
import { inspectionFormApi } from "@/api/inspectionForm.js";
import stationDialog from "./commponents/stationDialog.vue";
export default {
  name: "",
  components: { stationDialog },
  data() {
    return {
      approverName:'',
      ComList:'',
      url: '',
      srcList: [],
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        taskName:'',
        taskNum:'',
        approverName: "",
        stationName: "",
        stationId: "",
        operationUserName: "",
        planStartAtStart: null,
        planStartAtEnd: null,
        startAtStart: null,
        startAtEnd: null,
      },
      fileListBtn: [
        {
          maintainTaskExplain: '',
          file: '',
          sysFile: [],
        },
      ],
      fileListBtnLook: [],
      total: 0,
      inputValue: 1,
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      dialogStatus: false,
      submitVisible: false,
      executeVisible: false,
      statusVisible: false,
      approveVisible: false,
      requestType: -1,
      statusType: 0,
      detailsData: {},
      taskForm: {
        stationId: "",
        approverId: "",
        taskName: "",
        planStartTime: "",
        planEndTime: "",
        operationUserId: "",
        remark: "",
        companyId:'',
        // principalId: "",
      },
      defaultData: {
        stationId: "",
        taskName: "",
        planStartTime: "",
        planEndTime: "",
        // startTime: '',
        // endTime: '',
        operationUserId: "",
        remark: "",
        // principalId: "",
      },
      stationName: "",
      id: "",
      approveResult: {
        state: "",
        approverOpinion: "",
        approverId: "",
        approverUser: '',
        score: null,
      },
      operationUserId: '',
      formRules: {
        grabMaintainTaskExplain: [
          {
            required: true,
            message: "请输入抢修工作明细说明",
            trigger: "blur",
          },
        ],
        grabMaintainMoney: [
          { required: true, message: "请输入抢修金额", trigger: "blur" },
        ],
        approverId: [
          { required: true, message: "请选择审批人", trigger: "change" },
        ],
        approverOpinion: [
          { required: true, message: "请输入审批意见", trigger: "blur" },
        ],
        state: [
          { required: true, message: "请选择审批结果", trigger: "change" },
        ],
      },
      loading: false,
      rules: {
        stationId: [
          { required: true, message: "请选择变配电站", trigger: "change" },
        ],
        taskName: [
          { required: true, message: "请输入任务标题", trigger: "blur" },
        ],
        operationInspectionFormId: [
          { required: true, message: "请选择巡检单", trigger: "change" },
        ],
        companyId: [
          { required: true, message: "请选择运维单位", trigger: "change" },
        ],
        planStartTime: [
          { required: true, message: "请选择计划开始时间", trigger: "change" },
        ],
        planEndTime: [
          { required: true, message: "请选择计划完成时间", trigger: "change" },
        ],
        operationUserId: [
          { required: true, message: "请选择执行人", trigger: "change" },
        ],
        approverId: [
          { required: true, message: "请选择审批人", trigger: "change" },
        ],
        // principalId: [
        //   { required: true, message: "请选择负责人", trigger: "change" },
        // ],
        resultDesc: [
          { required: true, message: "请输入执行结果", trigger: "blur" },
        ],
      },
      fileList: [],
      file: [],
      previewList: [],
      executeResult: {
        resultDesc: "",
      },
      stationId: 0,
      pictureUrl: "",
      userData: [],
      operationUserList:[],
      stationData: [],
      selectTestTask: [],
      pickerOptionsStart: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000;
        },
      },
      completeDetails: {},
      formDataExecute: [],
      successList: [],
      authorityListBtn: [],
      modifyDetails:'',
      userId:'',
    };
  },

  created() {
    this.$store.commit("increment", "运维管理");
    this.$store.commit("selectChild", "维修任务");
    this.$store.commit("selectChildren", "");
  },
  mounted() {
    this.queryTestTask();
    this.queryStationName();
    // this.queryUser();
    this.authorityListBtn = sessionStorage.getItem('roleInfo')
    // this.queryTestItem();
    this.userId=JSON.parse(sessionStorage.getItem("user_info")).id
    // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',this.userId);
  },

  methods: {
    fun() {
      console.log('空格')
    },
    //按钮权限
    isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },
    //金额校验
    handleInput(value, index) {
      console.log(value, index);
      const reg = /^([1-9]\d*|0)(\.\d{1,3})?$/ // 正则表达式，匹配大于0的三位小数
      if (reg.test(value)) {
        this.formDataExecute[index].maintainMoney = value; // 
      } else {
        this.formDataExecute[index].maintainMoney = ''
      }

    },
    //维修项保存
    executePreserve(item, index) {

      let itemObj = this.formDataExecute[index]
      // console.log('~~~~~~~~~~~', this.formDataExecute[index], itemObj);
      console.log(itemObj);
      console.log('~~~~~~',item);
      let typeShow = []
      let message = ''
      if (itemObj.file.length == 0) {
        typeShow.push(false)
        message = '图片不能为空'
      }
      if (itemObj.maintainTaskExplain == '') {
        typeShow.push(false)
        message = '维修结果不能为空'
      }
      if (itemObj.maintainMoney == '') {
        typeShow.push(false)
        message = '维修金额不能为空'
      }
      if (itemObj.resultStatus == '' || itemObj.resultStatus == null) {
        typeShow.push(false)
        message = '维修状态不能为空'
      }
      if (message != '') {
        this.$message({
          message: message,
          type: "warning",
          duration: 3000,
          customClass: "messageText",
        });
      }
      if (itemObj.maintainMoney) {
        const reg = /^([1-9]\d*|0)(\.\d{1,3})?$/ // 正则表达式，匹配大于0的三位小数
        if (!reg.test(itemObj.maintainMoney)) {
          typeShow.push(false)
          this.$message({
            message: '维修金额只能输入0或者0以上的三位小数',
            type: "warning",
            duration: 3000,
            customClass: "messageText",
          });

        }
      }


      if (!typeShow.includes(false)) {
        let obj = {
          operationMaintainTaskItemId: item.id,
          maintainMoney: itemObj.maintainMoney,
          maintainTaskExplain: itemObj.maintainTaskExplain,
          resultStatus: itemObj.resultStatus,
        }
        let formData = new FormData()
        if(itemObj.id){
          obj.id=itemObj.id
        }
        for (let key in obj) {
          formData.append(key, obj[key]);
        }
        let file = this.formDataExecute[index].file
        for (let i in file) {
          if(!file[i].id){
            console.log("111111");
            formData.append('multipartFiles', file[i].raw);
          }
        }
        let func= itemObj.id ? taskListItemAddUpdate(formData):taskListItemUpdate(formData)
        func.then(res => {
          console.log(res);
          if (res.code == 200) {
            this.successList.push(res.data)
            this.$message({
              message: '保存成功',
              type: "success",
              duration: 3000,
              customClass: "messageText",
            });
            // this.formDataExecute[index].btn=false
            this.queryTaskItem()

          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 3000,
              customClass: "messageText",
            });
          }
        })
      }
    },
    //完成
    executeDialog(item) {
      this.id = item.id;
      // his.requestType = -1;
      this.queryTaskItem()
      console.log('~~~~~222~~~', this.formDataExecute);
      //completeDetails

    },
    //获取详情
    queryTaskItem() {
      taskQueryId(this.id).then(res => {
        this.formDataExecute = []
        this.detailsData = res.data
        this.completeDetails = res.data
        for (let i = 0; i < res.data.operationMaintainTaskItemList.length; i++) {
          let obj = {
            id: '',
            maintainMoney: '',
            maintainTaskExplain: '',
            // multipartFiles:''
            resultStatus: res.data.operationMaintainTaskItemList[i].resultStatus ,
            file: [],
            btn: ''
          }
          let item = res.data.operationMaintainTaskItemList[i]
          if (item.operationMaintainTaskItemDetailsList.length != 0) {
            obj.btn = false

            let itemChild = item.operationMaintainTaskItemDetailsList[0]
            // console.log("~~~~~!!@@~~~", itemChild.sysFile.length);


            obj.id = itemChild.id
            obj.maintainMoney = itemChild.maintainMoney
            obj.maintainTaskExplain = itemChild.maintainTaskExplain
            // console.log("9999999999");
            if (itemChild.sysFile.length != 0) {
              for (let j = 0; j < itemChild.sysFile.length; j++) {
                // console.log("999999999");
                obj.file.push({
                  name: itemChild.sysFile[j].originalFileName,
                  url: itemChild.sysFile[j].url,
                  id: itemChild.sysFile[j].id
                })
              }
            }
            // obj.file=itemChild.sysFile.

          } else {
            obj.btn = true
          }

          this.formDataExecute.push(obj)
          console.log('~~11111111111~~~~', this.formDataExecute);
        }
        this.executeVisible = true;

      })
    },
    //删除此项
    deleteTerm(index, item) {
      console.log(item);
      if (item.id) {
        taskItemDelete(item.id).then(res => {
          if (res.code == 200) {
            this.fileListBtn.splice(index, 1)
            this.$message({
              message: res.message,
              type: "success",
              duration: 3000,
              customClass: "messageText",
            });
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 3000,
              customClass: "messageText",
            });

          }
        })
      } else {
        this.fileListBtn.splice(index, 1)
      }
    },
    //添加
    addFile() {
      this.fileListBtn.push({ maintainTaskExplain: '', file: '', sysFile: [] })
    },
    getStationName(value) {
      if (value.id) {
        this.taskForm.stationId = value.id;
        this.stationName = value.stationName;
          this.taskForm.companyId=''
          this.taskForm.operationUserId='',
          this.approverName=''
          this.approverId=''
        this.gatComList()
      }
      console.log("222222");
      this.dialogStatus = false;
    },
    //获取运维单位列表
    gatComList(){
      getStaComList(this.taskForm.stationId).then(res=>{
          this.ComList=res.data
          console.log("111");
          if(this.taskForm.companyId){
            this.obtainUser(this.taskForm.companyId)
            // 
          }
        })
    },
    
    //选择运维单位
    changeObtainUser(value){
      console.log(value);
     let obj= this.ComList.filter(item=>item.companyId===value)[0]
     console.log(obj);
     //contactNameId
    //  this.taskForm.approverId=obj.contactNameId
     this.approverName=obj.contactName
     this.userData=obj.comUserList
     this.operationUserList = obj.fuzerenUserList
     if(this.taskForm.operationUserId){
       this.taskForm.operationUserId=null
     }
     if(this.taskForm.approverId){
       this.taskForm.approverId=null
     }
    },
    //选择运维单位
    obtainUser(value){
      console.log('1111',value);
     let obj= this.ComList.filter(item=>item.companyId===value)[0]
     console.log(obj);
     //contactNameId
    //  this.taskForm.approverId=obj.contactNameId
     this.approverName=obj.contactName
     this.userData=obj.comUserList
     this.operationUserList = obj.fuzerenUserList
    },
    changeDialog(value) {
      console.log("111111");
      this.dialogStatus = value;
    },
    selectStation() {
      this.dialogStatus = true;
    },
    submitConfirm() {
      if (this.requestType === 0) {
        this.dialogConfirm("taskForm");
      } else {
        this.updateTaskStatus(this.id, 2);
      }
    },
    statusDialog(item, status) {
      this.id = item.id;
      this.statusType = status;
      this.statusVisible = true;
      this.lookItem(item)
    },
    statusConfirm() {
      this.updateTaskStatus(this.id, this.statusType);
    },
    dialogClose(formName) {
      this.stationName = "";
      this.requestType=''
      this.fileList = [];
      this.file = ''
      this.approverName=''
      this.completeDetails = ''
      this.approveResult = {
        state: "",
        approverOpinion: "",
        approverId: "",
        approverUser: '',
        score: null,
      };
      this.taskForm.approverId = null
      this.taskForm.companyId = ''
      this.stationId = 0
      this.ComList=[]

      // this.formDataExecute = []
      // this.previewList = [];
      // this.pictureUrl = "";
      // this.executeResult.resultDesc = "";
      // Object.assign(this.taskForm, this.defaultData);
      this.$refs[formName].resetFields();
      this.fileListBtn = [
        {
          maintainTaskExplain: '',
          file: '',
          sysFile: [],
        }
      ]
      // this.$refs.taskForm.clearValidate();
    },
    handleRemove(file, fileList) {
      console.log('~~~~~~~~~11~~~~~~~', file, fileList);
      if (file.id) {
        deleteSysFile(file.id).then(res => {
          console.log(res);
        })
      }
      if (fileList.length === 0) {
        this.file = [];
      } else {
        this.file = fileList.map((item) => item.raw);
      }
    },
    pictureChange(file, fileList) {
      this.file = file;
      if (this.file.length !== 0) {
        this.$message({
          message: "图片只能上传一张",
          duration: 3000,
          customClass: "messageText",
        });
        if (this.requestType == 1) {
          let formData = new FormData()
          formData.append('dataId', this.id)
          formData.append('tableFlage', 'task')
          formData.append('multipartFiles', file.raw)
          uploadSysFile(formData).then(res => {
            console.log(res);
            if (res.code !== 200) {
              this.fileList = []
            }
          })

        }
      }
      console.log(this.file);
    },
    pictureChangeService(file, fileList, index, type, item) {
      console.log(file, fileList, index, type,);
      if (type == 2) {
        this.fileListBtn[index].sysFile = fileList
        if (item.id) {
          let formData = new FormData()
          formData.append('dataId', item.id)
          formData.append('tableFlage', 'item')
          formData.append('multipartFiles', file.raw)
          uploadSysFile(formData).then(res => {
            console.log(res);
            if (res.code !== 200) {
              this.fileList = []
            }
          })
        }
      }
      if (type == 3) {
        this.formDataExecute[index].file = fileList
        let itemDetails=this.formDataExecute[index]
        console.log(fileList,itemDetails);
        console.log(item);
        if(itemDetails.id){
          let formData = new FormData()
          formData.append('dataId', itemDetails.id)
          formData.append('tableFlage', 'details')
          formData.append('multipartFiles', file.raw)
          uploadSysFile(formData).then(res => {
            console.log(res);
            if (res.code !== 200) {
              this.fileList = []
            }
          })
        }
      }
      // this.file = ;
      // if (this.file.length !== 0) {
      //   this.$message({
      //     message: "图片只能上传一张",
      //     duration: 3000,
      //     customClass: "messageText",
      //   });
      // }
    },
    handleRemoveTaskItem(file, fileList, index, type, item) {
      // console.log("``````````````", file, fileList,);
      console.log(item);
      if (type == 2) {
        console.log( this.fileListBtn[index]);
        this.fileListBtn[index].sysFile = fileList
        // console.log("222222222222222",file);
        if (file.id) {
          deleteSysFile(file.id).then(res => {
            console.log(res);
          })
        }
      }
      if (type == 3) {
        this.formDataExecute[index].file = fileList
        // console.log('33333333333333', this.formDataExecute[index]);
        if (file.id) {
          deleteSysFile(file.id).then(res => {
            console.log(res);
          })
        }
      }
      // this.file = ;
      // if (this.file.length !== 0) {
      //   this.$message({
      //     message: "图片只能上传一张",
      //     duration: 3000,
      //     customClass: "messageText",
      //   });
      // }
    },
    createDialog() {
      this.requestType = 0;
      Object.assign(this.taskForm, this.defaultData);
      this.dialogVisible = true;
    },
    updateDialog(data) {
      this.requestType = 1;

      this.dialogVisible = true;
      taskQueryId(data.id).then(res => {
        // this.taskForm = res.data
        this.id = res.data.id;
        this.stationName = res.data.stationName;
        this.taskForm.stationId = res.data.stationId;
        this.taskForm.taskName = res.data.taskName;
        this.taskForm.companyId = res.data.companyId;
        this.taskForm.approverId = res.data.approverId;
        this.taskForm.operationUserId = res.data.operationUserId;
        this.taskForm.planStartTime = res.data.planStartTime;
        this.taskForm.planEndTime = res.data.planEndTime;
        this.taskForm.remark = data.remark;
        this.modifyDetails=data.state
        this.file = res.data.sysFile
        this.fileList = res.data.sysFile
        this.fileListBtn = res.data.operationMaintainTaskItemList
        this.gatComList()
       
      })


    },
    viewDialog(data) {
      this.id = data.id;
      this.requestType = -1;
      this.viewVisible = true;
      this.lookItem(data)
     
    },

    lookItem(data) {
      this.fileListBtnLook = []
      taskQueryId(data.id).then(res => {
        this.detailsData = res.data
         if(this.detailsData.userScore){
          this.detailsData.userScore=res.data.userScore
         } else{
          this.detailsData.userScore=0
         }
        // console.log('~~~~~~~~', this.detailsData.userScore);
        if (res.data.sysFile.length > 0) {

          this.detailsData.url = res.data.sysFile[0].url
          this.detailsData.fileList = []
          this.detailsData.fileList.push(res.data.sysFile[0].url)
          this.fileList = res.data.sysFile
          this.fileListBtnLook = []
        }
        // this.fileListBtnLook = res.data.operationMaintainTaskItemList
        res.data.operationMaintainTaskItemList.forEach(item => {
          console.log("~21~~~~1~~2~3~~~~~~~", item.resultStatus);
          let obj = {
            maintainTaskExplain: item.maintainTaskExplain,
            sysFile: [],
            fileList: [],
            resultStatus: item.resultStatus,
            result: {
              fileList: []
            },
          }
          for (let i in item.sysFile) {
            // console.log("456789");
            obj.sysFile.push({
              // name: item.sysFile[i].originalFileName,
              url: item.sysFile[i].url,
            })
            obj.fileList.push(item.sysFile[i].url)
          }
          if (item.operationMaintainTaskItemDetailsList.length > 0) {
            console.log("~~!!!~~~!!~~!~~~~", item.operationMaintainTaskItemDetailsList[0]);
            obj.result = item.operationMaintainTaskItemDetailsList[0]
            obj.result.fileList = []

            for (let j in item.operationMaintainTaskItemDetailsList[0].sysFile) {
              obj.result.fileList.push(item.operationMaintainTaskItemDetailsList[0].sysFile[j].url)
            }
          }
          this.fileListBtnLook.push(obj)
        })
        console.log('this.fileListBtnLook', this.fileListBtnLook);
      })

    },
    deleteDialog(item) {
      this.id = item.id;
      this.deleteVisible = true;
    },
    submitDialog(item) {
      this.requestType = -2;
      this.id = item.id;
      this.submitVisible = true;
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryTestTask();
    },
    //审批
    approveDialog(item) {
      this.id = item.id;
      this.approveResult.approverUser = item.approverUser;
      this.approveResult.approverId = item.approverId;
      this.operationUserId = item.operationUserId;
      this.approveVisible = true;
      this.lookItem(item)
    },
    //审核驳回
    approveRejectDialog(item){
      this.id = item.id;
      this.approveResult.approverUser = item.approverUser;
      this.approveResult.approverId = item.approverId;
      this.operationUserId = item.operationUserId;
      //shenhe 
      this.updateTaskStatus(
                this.id,
                1,
                this.approveResult.approverId,
                this.approveResult.approverOpinion
              );
    },
    approveConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            taskId: this.id,
            score: this.approveResult.score,
            operationUserId: this.operationUserId,
            type:1,
          }
          userScore(obj).then(res => {

              this.updateTaskStatus(
                this.id,
                this.approveResult.state,
                this.approveResult.approverId,
                this.approveResult.approverOpinion
              );
            
          })
        } else {
          return false;
        }
      });
    },
    selectChange(item) {
      item.showStatus = !item.showStatus;
    },
    // executeDialog(item) {
    //   this.id = item.id;
    //   this.executeVisible = true;
    // },

    checkDateTime() {
      if (this.taskForm.planStartTime && this.taskForm.planEndTime) {
        const startTime = new Date(this.taskForm.planStartTime).getTime();
        const endTime = new Date(this.taskForm.planEndTime).getTime();
        if (startTime == endTime) {
          this.$message({
            message: "计划开始时间与计划结束时间不能相同",
            type: "warning",
            duration: 3000,
            customClass: "messageText",
          });
          this.taskForm.planEndTime = null;
        }
        if (startTime > endTime) {
          this.$message({
            message: "计划开始时间不能大于计划结束时间",
            type: "warning",
            duration: 3000,
            customClass: "messageText",
          });
          this.taskForm.planEndTime = null;
        }
      }
    },

    executeConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateTaskStatus(this.id, 4);
        } else {
          return false;
        }
      });
    },
    changeStatus(state, formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (state === 2) {
            this.taskForm.state = state;
            this.submitVisible = true;
          } else {
            this.taskForm.state = state;
            this.dialogConfirm("taskForm");
          }
        } else {
          return false;
        }
      });
    },
    //提交基本信息
    dialogConfirm(formName) {
      //新建维修任务
      let type = []
      if (this.requestType === 0) {
        type = []
        if (this.fileListBtn.length == 0) {
          type.push(false)
        }
        this.fileListBtn.forEach(item => {
          if (item.maintainTaskExplain && item.maintainTaskExplain.trim() != '') {
            type.push(true)
          } else {
            type.push(false)
          }
        })
        if (!type.includes(false)) {
          let formData = new FormData();
          for (let key in this.taskForm) {
            formData.append(key, this.taskForm[key]);
          }
          let file = this.file.raw ? this.file.raw : ''

          if (this.file.raw) {
            // formData.append('empty',true)
            formData.append('multipartFiles', file)

            // formData.append('multipartFiles', true)
          } else {
            // formData.append('empty',false)
          }
          // console.log("````````````", file);
          // formData.append('', file)
          // console.log(this.taskForm);
          taskListAdd(formData).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: res.message,
                type: "success",
                duration: 3000,
                customClass: "messageText",
              });
              // this.dialogVisible = false;
              // this.submitVisible = false;
              this.serviceTrimFile(res.data)
              // console.log(this.file);
            } else {
              this.$message({
                message: res.message,
                type: "error",
                duration: 3000,
                customClass: "messageText",
              });
            }
          });
        } else {
          this.$message({
            message: '维修项内容不能为空',
            type: "warning",
            duration: 3000,
            customClass: "messageText",
          });
        }
      } else {
        console.log("~~~~~~", this.fileListBtn);
        type = []
        if (this.fileListBtn.length == 0) {
          type.push(false)
        }
        this.fileListBtn.forEach(item => {
          // if(item.id){
          //   type.push(true)
          // }else{
          if (item.maintainTaskExplain && item.maintainTaskExplain.trim() != '') {
            type.push(true)
          } else {
            type.push(false)
          }
          // }
        })
        if (!type.includes(false)) {

          this.updateTestTask();
        } else {
          this.$message({
            message: '维修项内容不能为空',
            type: "warning",
            duration: 3000,
            customClass: "messageText",
          });
        }

      }
    },
    // 新增维修项
    serviceTrimFile(id) {
      console.log(id);
      console.log(this.fileListBtn);
      let request = []
      // if(this.fileListBtn.length >0 &)
      this.fileListBtn.forEach(item => {
        console.log('111~~!!', item);
        if (item.maintainTaskExplain && item.maintainTaskExplain.trim() != '') {
          let obj = {
            operationMaintainTaskId: id,
            // multipartFiles: item.file,
            maintainTaskExplain: item.maintainTaskExplain,
          }
          let formData = new FormData()
          for (let key in obj) {
            formData.append(key, obj[key]);
          }
          for (let i in item.sysFile) {
            formData.append('multipartFiles', item.sysFile[i].raw)
          }
          taskListAddItem(formData).then(res => {
            console.log('taskListAddItem===>', res);
            if (res.code == 200) {
              request.push(true)
            } else {
              request.push(false)
            }
          })
        }
      })
      if (!request.includes(false)) {
        this.$store.dispatch("queryMessage");
        this.dialogVisible = false;
        this.submitVisible = false;
        this.queryTestTask();
      } else {

      }
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    // 修改基本信息
    updateTestTask() {
      let formData = new FormData();
      this.taskForm.id = this.id;
      // delete this.taskForm.operationMaintainTaskItemList
      // let file = {}
      // if (this.file.raw) {
      //   file = this.file.raw
      // } else {
      //   file = ''
      // }
      // if (this.file.length !== 0) {
      //   file = this.file;
      //   console.log('`````````````````', file);
      //   // formData.append('empty', true)
      // } else {
      //   // formData.append('empty', false)
      //   file = ''
      // }
      this.taskForm.state = this.modifyDetails ? this.modifyDetails : 1;
      for (let key in this.taskForm) {
        formData.append(key, this.taskForm[key]);
      }

      // formData.append('multipartFiles', file.raw)
      console.log(this.taskForm);
      taskListUpdate(formData).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.updateTaskItem()
          // this.dialogVisible = false;
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    //修改维修项信息
    updateTaskItem() {
      //taskListUpdateItem
      console.log(this.fileListBtn);
      let request = []
      this.fileListBtn.forEach(item => {
        if (item.id) {
          let obj = {
            id: item.id,
            maintainTaskExplain: item.maintainTaskExplain,
          }
          let formData = new FormData()
          for (let key in obj) {
            formData.append(key, obj[key]);
          }
          // for (let i in item.file) {
          //   formData.append('multipartFiles', item.file[i])
          // }
          taskListUpdateItem(formData).then(res => {
            console.log('taskListAddItem===>', res);
            if (res.code == 200) {

              request.push(true)
            } else {
              request.push(false)
            }
          })
        } else {
          let obj = {
            operationMaintainTaskId: this.id,
            maintainTaskExplain: item.maintainTaskExplain,
          }
          let formData = new FormData()
          for (let key in obj) {
            formData.append(key, obj[key]);
          }
          console.log("222222", item.sysFile);
          for (let i in item.sysFile) {
            console.log("11111111111");
            formData.append('multipartFiles', item.sysFile[i].raw)
          }
          taskListAddItem(formData).then(res => {
            console.log('taskListAddItem===>', res);
            if (res.code == 200) {

              request.push(true)
            } else {
              request.push(false)
            }
          })

        }
      })
      if (!request.includes(false)) {
        this.$store.dispatch("queryMessage");
        this.dialogVisible = false;
        this.submitVisible = false;
        this.queryTestTask();
        this.modifyDetails=''
      } else {

      }

    },

    //修改状态
    updateTaskStatus(id, status) {
      if (this.executeResult.resultDesc) {
        let obj = {
          id: id,
          state: status
        }
        taskListState(obj)
          .then((res) => {
            if (res.code === 200) {
              this.$message({
                message: res.message,
                type: "success",
                duration: 3000,
                customClass: "messageText",
              });
              this.submitVisible = false;
              this.executeVisible = false;
              this.statusVisible = false;
              this.$store.dispatch("queryMessage");
              this.queryTestTask();
            } else {
              this.$message({
                message: res.message,
                type: "error",
                duration: 3000,
                customClass: "messageText",
              });
            }
          });
      } else {
        let approverOpinion = {
          id: id,
          state: status,
          approverOpinion: this.approveResult.approverOpinion
        }

        taskListState(approverOpinion).then((res) => {
          if (res.code === 200) {
            this.$message({
              message: res.message,
              type: "success",
              duration: 3000,
              customClass: "messageText",
            });
            this.submitVisible = false;
            this.executeVisible = false;
            this.statusVisible = false;
            this.approveVisible = false
            this.queryTestTask();
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 3000,
              customClass: "messageText",
            });
          }
        });
      }
    },
    //删除文件
    deleteFile() {

    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryTestTask();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryTestTask();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryTestTask();
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryTestTask();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryTestTask();
    },
    queryTestTask() {
      this.loading = true;
      taskListSearch(this.requestParam).then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    // queryUser() {
    //   testTaskApi.queryUser().then((res) => {
    //     if (res.code === 200) {
    //       this.userData = res.data;
    //     }
    //   });
    // },
    queryStationName() {
      inspectionFormApi.queryStationName().then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
        }
      });
    },


    unique(array) {
      return array.reduce((prev, next) => {
        return prev.includes(next) ? prev : [...prev, next];
      }, []);
    },
    createTestTask() {

    },

    deleteTestTask() {
      deleteTAskList(this.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryTestTask();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
    "taskForm.stationId": {
      // 执行方法
      handler(newValue, oldValue) {
        if (newValue) {
          this.stationId = newValue;
        }
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
  },
  computed: {
    pickerOptionsEnd() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.taskForm.planStartTime);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.requestParam.startTimeVo);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
  },
};
</script>

<style src="@/utils/style/public-style.css" scoped></style>

<style scoped>
#test-task {
  width: 100%;
  min-height: 80vh;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}


.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

.form .query-date :deep() .el-form-item__label {
  width: 93px;
}

/* 弹窗 */
.dialog-content {
  margin: 20px 41px 0 41px;
}

.form-title {
  font-family: "Arial Bold", "Arial Normal", "Arial", sans-serif;
  font-weight: 700;
  color: #c9d4f1;
  font-size: 16px;
  margin-bottom: 16px;
}

.dialog-form :deep() .el-input-group__append {
  background: #143168;
  color: #fff;
  border: 0;
  cursor: pointer;
}

/* input */
.dialog-form :deep() .el-date-editor {
  width: 240px;
}

.dialog-form :deep() .el-select {
  width: 240px;
}

.dialog-form :deep() .el-input {
  width: 240px;
}

.dialog-form :deep() .el-input .el-input__inner {
  /* height: 35px; */
  /* width: 100%; */
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .block {
  width: 620px;
}

/* 设计图上的样式 ↓ */
.form-remark {
  width: 620px;
  background-color: #061b45;
  border: 1px solid #09295b;
  box-sizing: border-box;
}

.dialog-form :deep() .el-upload--picture-card {
  margin: 0 15px 15px 15px;
  width: 60px;
  height: 60px;
  background: #143168;
  border: 1px solid #143168;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

/* 设计图上的样式 ↑  */
.dialog-form :deep() .el-textarea textarea {
  width: 580px;
  background-color: #061b45;
  border: 1px solid #09295b;
  /* border: 0; */
  font-size: 12px;
  color: #aed6ff;
}

.fileListBtnCls>>>.el-textarea__inner {
  width: 450px !important;
}

.dialog-form :deep() .special .el-form-item__label {
  width: 100px;
}

.el-upload-tip {
  color: #e8f4ff;
  font-size: 12px;
  margin-left: 8px;
}

/* 巡检内容 */
.interval {
  width: 100%;
  height: 1px;
  background-color: #06224e;
  margin-bottom: 16px;
}

.select-title {
  width: 669px;
  height: 40px;
  border: 1px solid #09295b;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
}

.select-title span {
  font-family: "Arial", sans-serif;
  color: #c9d4f1;
  font-size: 12px;
  line-height: 40px;
}

.select-content {
  padding: 0 16px;
  background-color: #041c4c;
  border-bottom: 1px solid #06224e;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.select-content span {
  color: #aed6ff;
  font-size: 12px;
}

.selected-inspection {
  background-color: #041c4c;
}

.no-data {
  height: 60px;
  color: #c9d4f1;
  font-size: 12px;
  line-height: 60px;
  text-align: center;
  background-color: #041c4c;
  margin: 16px 0;
}

/* 执行结果 */
.execute-dialog {
  margin: 16px 48px 16px 40px;
}

.execute-form :deep().el-form-item__label {
  width: 67px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
}

.execute-form :deep().el-form-item__label::after {
  content: ":";
  margin: 0 3px;
}

.execute-form :deep() .el-textarea textarea {
  height: 35px;
  width: 364px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.execute-form :deep() .el-textarea textarea::placeholder {
  font-size: 12px;
  color: #6180a8;
}

/* 时间选择器 */
/*时间日期选择器默认样式修改 */
.dialog-form :deep() .el-picker-panel .el-input {
  width: 100%;
}

::v-deep .el-time-panel {
  background: #041c4c;
  border: 0;
  color: #aed6ff;
}

::v-deep .el-time-panel__footer {
  border-color: #aed6ff;
}

::v-deep .el-time-spinner__item {
  color: #aed6ff;
}

::v-deep .el-time-spinner__item.active:not(.disabled) {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn.confirm {
  color: #409eff;
}

::v-deep .el-picker-panel__footer {
  color: #aed6ff;
  background: #041c4c;
}

::v-deep .is-plain {
  background: #176ad2;
  border: 0;
  color: #fff;
}

::v-deep .el-time-spinner__item:hover:not(.disabled):not(.active) {
  background: #041c4c;
}

::v-deep .is-plain:hover {
  background: #176ad2;
  border: 0;
  color: #fff;
}

.deleteFile {
  font-size: 16px;
}

.status-dialog {
  margin: 16px 48px 16px 40px;
}

.status-form :deep().el-form-item__label {
  width: 67px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
}

.status-form :deep().el-form-item__label::after {
  content: ":";
  margin: 0 3px;
}

.status-form :deep() .el-form-item__content {
  width: 374px;
}

.status-form :deep() .el-form-item {
  margin: 0 0 16px 0;
}

.status-form :deep() .approve-result {
  margin: 0 0 6px 0 !important;
}

.status-form :deep() .el-textarea textarea {
  /* height: 35px; */
  width: 620px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.status-form :deep() .el-input input {
  height: 35px;
  width: 100%;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.status-form :deep() .el-rate {
  margin-top: 10px;
}

.status-form :deep() .el-textarea textarea::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.status-form :deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.status-form :deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

.status-form :deep() .el-radio {
  margin-right: 16px;
  color: #aed6ff;
}

.dialogBody {
  margin: 10px 30px;
}

.dialogBodyForm :deep() .el-form-item__label {
  color: #e8f4ff;
  margin: 0;
}

.dialogBodyForm :deep() .el-form-item {
  color: #e8f4ff;
  margin: 0;
}

.dialogBody {
  color: #aed6ff;
}

.dialogBodyItem {
  margin-left: 30px;
  color: #e8f4ff;

}

.fileList {
  display: flex;
  /* height: 200px;
  line-height: 200px; */
  /* padding: 10px 0px; */
}

.fileListItem {
  margin-right: 10px;
  margin-bottom: 10px;
}

.resultText {
  font-size: 14px;
  color: #aed6ff;
}

::v-deep .el-dialog__body {
  max-height: 800px;
  overflow: auto;
}

::v-deep .el-dialog__body::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-dialog__body::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}

.myDialogForm :deep() .el-input {
  width: 500px;
}

.myDialogForm :deep() .el-form-item {
  width: 100%;
  margin-bottom: 10px;
}

.select {
  border: 1px solid #003D74;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 30px;
  width: 580px;
  position: relative;
}
.deleteItem{
  color: #aed6ff;
  cursor: pointer;
  font-size:20px;
  position: absolute;
  top: -15px;
  right: -10px;
}
.selectLook {
  border: 1px solid #003D74;
  padding: 15px;
  margin-bottom: 30px;
}

.executeItem>>>.el-textarea__inner {
  width: 500px !important;
  font-family: "Microsoft YaHei", Arial, sans-serif;
}

::v-deep .el-textarea__inner {
  width: 500px !important;
  font-family: "Microsoft YaHei", Arial, sans-serif;

}

::v-deep .el-picker-panel__footer .el-button--text {
  display: none;
}

.myRate {
  line-height: 50px;
}

::v-deep .el-rate__text {
  display: none;
}



.imgFile {
  display: flex;
  color: #e8f4ff;
  font-size: 12px;
  text-align: right;
}

.imgFileText {
  text-align: right;
  margin-right: 20px;
}

.approveResultForm {
  margin-bottom: 20px;
}

.resultStatus {
  width: 500px !important;
}
/* ::v-deep .dialog-form[data-v-e6ef877c] .el-form-item__label {
  width: 100px !important;
} */
.dialog-form :deep() .el-form-item__label {
  width: 100px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
  box-sizing: border-box;
}
.resultItem{
  margin-left: 30px;
}
.resultItem .resultTitle{
  font-size: 16px;
  color: #ffffff;
}
.resultItem >>> .el-form{
  margin-left: 20px;
}
.btnRight{
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 15px;
}
.basisRemark >>> .el-textarea__inner{
  width: 620px !important;
}
.dialog-text{
  text-align: center;
  margin: 50px 0;
}
.executePreserveBtn{
  display: flex;
  flex-direction: row-reverse;
}
.itemTitle{
  font-size: 20px;
  margin-bottom: 10px;
}
.noImage{
  font-size: 14px;
  color: #e8f4ff;
}
::v-deep .el-input__inner{
    height: 40px !important;
  }
</style>
